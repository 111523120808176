import React, { useEffect, useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import {
	Autocomplete,
	IconButton,
	InputAdornment,
	OutlinedInput,
	TextField,
} from '@mui/material';

import { Button } from '../../components';
import { listTransactionColumn } from '../../data/columnData';
import DataTable from 'react-data-table-component';

import {
	BASE_URL,
	BASE_URL_ONLINE,
	URL_ERROR_LIST,
	URL_REPORTING_LIST,
	URL_REPORTING_LIST_V2,
} from '../../config';
import { FiRefreshCw } from 'react-icons/fi';
import { BiDollar, BiSpreadsheet } from 'react-icons/bi';
import { TiDocument } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';

import FormControl from '@mui/material/FormControl';
import Select from 'react-select';

import LogoMKP from '../../data/LogoMKP.png';
import HeaderPDF from '../../data/HeaderPDF.png';
import { MdSearch } from 'react-icons/md';
import notfound from '../../data/notfound.png';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment/moment';

import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	PDFDownloadLink,
	Image,
} from '@react-pdf/renderer';

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { BsFileEarmarkExcelFill } from 'react-icons/bs';
import { checkIsAvailableModule } from '../../Routes';
import { TransactionDetail } from './TransactionDetail';
import { MatrixUtils } from '../../utils/MatrixUtils';
import _ from 'lodash';
import { VoidTransaction } from './VoidTransaction';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import { getTimeStamp } from '../../utils/DateUtils';
import {
	encryptHmac512Base64,
	encryptSHA512,
} from '../../utils/CryptoUtils';
import { listTransactionColumnSettle } from '../../data/columnDataSettle';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '700',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '0px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

const SettledTransaction = () => {
	const navigate = useNavigate();

	const {
		activeMenu,
		currentColor,
		currentAuth,
		setAuth,
		modalOpenClose,
		hirarkiUser,
		screenWidth,
		privilege,
		dataLogin,
	} = useStateContext();

	const dateTo = moment().format('YYYY-MM-DD');
	const dateFrom = moment().subtract(6, 'd').format('YYYY-MM-DD');
	// data for filter
	const [startDate, setStartDate] = useState(dateFrom);
	const [endDate, setEndDate] = useState(dateTo);
	const [statusTrx, setStatusTrx] = useState(1);
	const [pembayaran, setPembayaran] = useState('');
	//   const [corporate, setCorporate] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [pagination, setPagination] = useState(1);
	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');
	const [dataStatusCode, setDataStatusCode] = useState([]);

	const [dataCoreSettle, setDataCoreSettle] = useState([]);
	const [kategoriPembayaran, setKategoriPembayaran] = useState([]);
	//   const [dataCorporate, setDataCorporate] = useState([]);
	const [dataSettle, setDataSettle] = useState([]);

	const [order, setOrder] = useState('desc');
	const [header, setHeader] = useState('');
	const [listTrx, setListTrx] = useState([]);
	const [dataExcel, setDataExcel] = useState([]);

	const [selected, setSelected] = useState([]);

	const dateStarted = moment(startDate).format('YYYY-MM-DD');
	const dateEnded = moment(endDate).format('YYYY-MM-DD');
	const [count, setCount] = useState(1);

	const [successData, setSuccessData] = useState({
		startDate: '',
		endDate: '',
		corporate: '',
		pembayaran: '',
		status: '',
		settlementDestination: '',
		jumlahTrx: 0,
		totalBruto: 0,
		totalMDR: 0,
		totalServiceFee: 0,
		totalPaymentFee: 0,
		totalVendorFee: 0,
		totalTunai: 0,
		totalLain: 0,
		totalCC: 0,
		totalDebit: 0,
		totalQRIS: 0,
		totalBrizzi: 0,
		totalEMoney: 0,
		totalTapCash: 0,
		totalFlazz: 0,
		totalJakcard: 0,
		totalVA: 0,
		totalBiller: 0,
		totalNett: 0,
		totalPotongan: 0,
	});

	const [selectedDetail, setSelectedDetail] = useState(null);

	const [dataFilter, setDataFilter] = useState([
		{ value: 'noHeader', label: 'Invoice Number' },
		{ value: 'deviceId', label: 'Device ID' },
		{ value: 'merchantNoRef', label: 'Merchant Reference' },
		{ value: 'cardPan', label: 'Card PAN' },
		{ value: 'issuerCode', label: 'Issuer Code' },
	]);

	const [selectedFilter, setSelectedFilter] = useState(dataFilter[0]);
	const [selectedVoid, setSelectedVoid] = useState(null);

	const dateToMax = moment(startDate).add(1, 'months').isAfter(dateTo)
		? dateTo
		: moment(startDate).add(1, 'months').format('YYYY-MM-DD');

	const getKategoriPembayaran = () => {
		fetch(BASE_URL + '/kategoripembayaran/list', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				setKategoriPembayaran(json.result);
			})
			.catch((err) => {
				console.log(('KategoriPembayaran Error: ', err));
			});
	};

	const getCoreSettle = () => {
		const bodyData = {
			order: 'ASC',
			limit: 0,
			page: 0,
		};
		fetch(BASE_URL + '/core/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.success) {
					if (data.result && data.result.length > 0) {
						let dataValue = _.cloneDeep(data.result);
						dataValue.map((o) => {
							o.value = o.id;
							o.label = o.name;
							return o;
						});

						setDataCoreSettle(dataValue);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
			});
	};

	const getCid = (mCorporate) => {
		if (mCorporate && mCorporate != null) {
			let mDataCorporate = _.cloneDeep(dataCorporate);
			let mFilter = mDataCorporate.filter(
				(o) => o.hirarkiId == mCorporate
			);

			if (mFilter.length > 0) {
				return mFilter[0].cid;
			}

			return '';
		}

		return '';
	};

	const getSuccessTransaction = (
		page = pagination,
		totalData = limit
	) => {
		modalOpenClose(true);

		const bodyData = {
			startDate: dateStarted,
			endDate: dateEnded,
			kategoriPembayaran: pembayaran,
			paymentStatus: parseInt(statusTrx),
			corporate: corporate ? corporate : hirarkiUser,
			sattlementDestination: selected.map((o) => {
				return { sattlementDestination: o.label };
			}),
			hirarkiId: hirarkiUser,
			settlementStatus: 'true',
			parameter: search ? selectedFilter.value : '',
			// cid: getCid(corporate ? corporate : null),
			cid: '',
			pagination: {
				order: order,
				limit: totalData,
				page: parseInt(page),
				keyword: search,
				orderBy: header,
			},
		};

		let mTimeStamp = getTimeStamp();
		let bodyEncripted = encryptSHA512(
			JSON.stringify(bodyData).toLowerCase().replace(/\s/g, '')
		);

		let dataPLain =
			'POST' +
			'||' +
			URL_REPORTING_LIST_V2 +
			'||' +
			(dataLogin.username ?? '') +
			'||' +
			bodyEncripted +
			'||' +
			mTimeStamp;

		let signature = encryptHmac512Base64(dataPLain);

		fetch(BASE_URL + URL_REPORTING_LIST_V2, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				'X-MKP-Timestamp': mTimeStamp,
				'X-MKP-Signature': signature,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.success) {
					if (data.result != null) {
						setListTrx(data.result);
						modalOpenClose(false);
					} else {
						setListTrx([]);
						modalOpenClose(false);
					}
				} else {
					setListTrx([]);
					modalOpenClose(false);
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				setListTrx([]);
				modalOpenClose(false);
			});
	};

	const getSuccessSummary = () => {
		const inputData = {
			startDate: dateStarted, // date will change
			endDate: dateEnded,
			corporate: corporate,
			kategoriPembayaran: pembayaran,
			settlementDestination: selected.map((o) => {
				return o.label;
			}),
		};
		fetch(BASE_URL + '/summary/settled', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.success === true) {
					setCount(json.result.jumlahTrx);
					setSuccessData({
						startDate: json.result.startDate,
						endDate: json.result.endDate,
						corporate: json.result.corporate,
						pembayaran: json.result.pembayaran,
						status: json.result.status,
						settlementDestination: json.result.settlementDestination,
						jumlahTrx: json.result.jumlahTrx,
						totalBruto: json.result.totalBruto,
						totalMDR: json.result.totalMDR,
						totalServiceFee: json.result.totalServiceFee,
						totalPaymentFee: json.result.totalPaymentFee,
						totalVendorFee: json.result.totalVendorFee,
						totalTunai: json.result.totalTunai,
						totalLain: json.result.totalLain,
						totalCC: json.result.totalCC,
						totalDebit: json.result.totalDebit,
						totalQRIS: json.result.totalQRIS,
						totalBrizzi: json.result.totalBrizzi,
						totalEMoney: json.result.totalEMoney,
						totalTapCash: json.result.totalTapCash,
						totalFlazz: json.result.totalFlazz,
						totalJakcard: json.result.totalJakcard,
						totalVA: json.result.totalVA,
						totalBiller: json.result.totalBiller,
						totalNett: json.result.totalNett,
						totalPotongan: 0,
					});
				} else {
					setCount(0);
					setSuccessData({
						startDate: '',
						endDate: '',
						corporate: '',
						pembayaran: '',
						status: '',
						settlementDestination: '',
						jumlahTrx: 0,
						totalBruto: 0,
						totalMDR: 0,
						totalServiceFee: 0,
						totalPaymentFee: 0,
						totalVendorFee: 0,
						totalTunai: 0,
						totalLain: 0,
						totalCC: 0,
						totalDebit: 0,
						totalQRIS: 0,
						totalBrizzi: 0,
						totalEMoney: 0,
						totalTapCash: 0,
						totalFlazz: 0,
						totalJakcard: 0,
						totalVA: 0,
						totalBiller: 0,
						totalNett: 0,
						totalPotongan: 0,
					});
				}
			})
			.catch((err) => {
				console.log('This is Error', err);
				setCount(0);
				setSuccessData({
					startDate: '',
					endDate: '',
					corporate: '',
					pembayaran: '',
					status: '',
					settlementDestination: '',
					jumlahTrx: 0,
					totalBruto: 0,
					totalMDR: 0,
					totalServiceFee: 0,
					totalPaymentFee: 0,
					totalVendorFee: 0,
					totalTunai: 0,
					totalLain: 0,
					totalCC: 0,
					totalDebit: 0,
					totalQRIS: 0,
					totalBrizzi: 0,
					totalEMoney: 0,
					totalTapCash: 0,
					totalFlazz: 0,
					totalJakcard: 0,
					totalVA: 0,
					totalBiller: 0,
					totalNett: 0,
					totalPotongan: 0,
				});
			});
	};

	const getDataExcel = (page = pagination, totalData = limit) => {
		modalOpenClose(true);
		const bodyData = {
			startDate: dateStarted,
			endDate: dateEnded,
			kategoriPembayaran: pembayaran,
			statusTrx: parseInt(statusTrx),
			corporate: corporate,
			sattlementDestination: selected.map((o) => {
				return { sattlementDestination: o.label };
			}),
			settlementStatus: 'true',
			parameter: search ? selectedFilter.value : '',
			// cid: getCid(corporate ? corporate : null),
			cid: '',
			pagination: {
				order: 'ASC',
				limit: parseInt(count),
				page: parseInt(page),
				keyword: '',
			},
		};

		let mTimeStamp = getTimeStamp();
		let bodyEncripted = encryptSHA512(
			JSON.stringify(bodyData).toLowerCase().replace(/\s/g, '')
		);

		let dataPLain =
			'POST' +
			'||' +
			URL_REPORTING_LIST_V2 +
			'||' +
			(dataLogin.username ?? '') +
			'||' +
			bodyEncripted +
			'||' +
			mTimeStamp;

		let signature = encryptHmac512Base64(dataPLain);

		fetch(BASE_URL + URL_REPORTING_LIST_V2, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				'X-MKP-Timestamp': mTimeStamp,
				'X-MKP-Signature': signature,
			},

			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.success) {
					if (data.result != null) {
						setDataExcel(data.result);
						exportToExcel(data.result);
						modalOpenClose(false);
					} else {
						setDataExcel([]);
						modalOpenClose(false);
					}
				} else {
					setDataExcel([]);
					modalOpenClose(false);
				}
			})
			.catch((err) => {
				modalOpenClose(false);
				console.log('err =>', err);
			});
	};

	const getStatusCode = () => {
		let mData = {
			errorsCode: '',
			errorsCategory: '',
			errorsDescription: '',
			errorsReadable: '',
		};

		fetch(BASE_URL_ONLINE + URL_ERROR_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Basic bWtwbW9iaWxlOm1rcG1vYmlsZTEyMw==`,
			},
			body: JSON.stringify(mData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						setDataStatusCode(data.result);
					} else {
						setDataStatusCode([]);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
			});
	};

	const handleChange = (event, values) => {
		setSelected(values);
	};

	const onChangePembayaran = (event, values) => {
		setPembayaran(values.value);
	};

	const onChangeSelectedFilter = (event, values) => {
		setKeyword('');
		setSelectedFilter(values);
	};

	const handlePageChange = (page) => {
		getSuccessTransaction(page);
		setCurrentPage(page);
	};

	const handlePerRowsChange = (totalData, page) => {
		getSuccessTransaction(page, totalData);
		setLimit(totalData);
	};

	const handleClick = () => {
		getSuccessTransaction();
		getSuccessSummary();
	};

	const searchData = () => {
		setSearch(keyword);
	};

	const fileType =
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';

	const exportToExcel = async (dataForExcel) => {
		const dataExcel = dataForExcel.map((o) => {
			let mFilter = dataStatusCode.filter(
				(m) => m.errorsCode == o.statusCode
			);
			let mData = {};
			mData.Status = o.paymentStatusDesc;
			mData.StatusDescription =
				mFilter.length > 0 ? mFilter[0].errorsReadable : 'Unknown';
			mData.InvoiceNo = o.noHeader;
			mData.MerchantRef = o.merchantNoRef;
			mData.CreatedDate = o.createdAt
				? o.createdAt.split(' ')[0]
				: '';
			mData.CreatedTime = o.createdAt
				? o.createdAt.split(' ')[1]
				: '';
			mData.Amount = o.paymentAmount;
			mData.PaymentCategory = o.paymentCategoryName;
			mData.PaymentName = o.paymentMethodName;
			mData.CoporateID = o.corporateCID;
			mData.CorporateName = o.corporateName;
			mData.SettledDest = o.settlementDestination;
			mData.SettledStatus = o.statusSettlement ? 'YES' : 'NO';
			mData.SettledAt = o.settledAt ?? '';
			mData.PrincipleRef = o.responseNoRef;
			mData.DeviceID = o.deviceId;
			mData.MID = o.bankMID;
			mData.TID = o.bankTID;
			mData.Cardpan = o.cardPan;
			mData.cardType = o.cardType;
			mData.STAN = o.stan ?? '';
			mData.RRN = o.rrn ?? '';
			mData.ApprovalCode = o.bankApprovalCode ?? '';
			mData.OnusOffus = o.cardTypeTrx ?? '';
			mData.MDR = o.paymentMDR;
			mData.ServiceFee = o.serviceFee;
			mData.PaymentFee = o.paymentFee;
			mData.VendorFee = o.vendorFee;

			return mData;
		});

		const ws = XLSX.utils.json_to_sheet(dataExcel);
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, {
			bookType: 'xlsx',
			type: 'array',
		});
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(
			data,
			`Data Transaksi (${dateStarted} - ${dateEnded})` + fileExtension
		);
	};

	const customSort = (rows, selector, direction) => {
		console.log(selector);
		setHeader(rows.selector);
		setOrder(selector);
	};

	// const corpData = [
	// 	{
	// 		value: '',
	// 		label: '- All Corporate -',
	// 	},
	// ];
	// {
	// 	dataCorporate.map((item) => {
	// 		let dataValue = {
	// 			value: item.hirarkiId,
	// 			label: item.uraian,
	// 		};
	// 		corpData.push(dataValue);
	// 	});
	// }

	const dataCategory = [
		{
			value: '',
			label: '- Semua Payment Category -',
		},
	];
	{
		kategoriPembayaran.map((item) => {
			let dataValue = {
				value: item.uraian,
				label: item.uraian,
			};
			dataCategory.push(dataValue);
		});
	}

	const getDetailData = (id) => {
		let mFilter = id ? listTrx.filter((o) => o.id == id) : [];

		if (mFilter.length > 0) {
			return mFilter[0];
		} else {
			return null;
		}
	};

	useEffect(() => {
		// getCorporate();
		getKategoriPembayaran();
		getCoreSettle();
		getSuccessSummary();
		getStatusCode();
	}, []);

	useEffect(() => {
		getSuccessTransaction();
	}, [search, order, header]);

	// fixed corporate filter bug
	const [value, setValue] = useState({
		label: '- All Corporate -',
		value: '',
	});
	const [dropdownSearch, setDropdownSearch] = useState('');
	const [corporate, setCorporate] = useState('');
	const [dataCorporate, setDataCorporate] = useState([]);
	const [corpData, setCorpData] = useState([]);
	const [searchTimeoutId, setSearchTimeoutId] = useState(null);
	const [corporateLoading, setCorporateLoading] = useState(false);

	const corpDropdown = () => {
		const data = dataCorporate.map((item) => ({
			value: item.hirarkiId,
			label: item.uraian,
		}));

		setCorpData(data);
	};

	const getCorporate = () => {
		const bodyData = {
			order: 'ASC',
			page: 1,
			limit: 200,
			keyword: dropdownSearch,
			hirarkiId: hirarkiUser,
		};

		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						setDataCorporate(data.result);
					} else {
						setDataCorporate([]);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
			})
			.finally(() => {
				setCorporateLoading(false);
			});
	};

	const onChangeCorp = (event, values) => {
		setCorporateLoading(false);
		setValue(values);
		setCorporate(values.value);
	};

	useEffect(() => {
		getCorporate();
	}, []);

	useEffect(() => {
		if (dataCorporate.length > 0) {
			corpDropdown();
		}
	}, [dataCorporate]);

	// Cleanup function to clear timeout on unmount
	useEffect(() => {
		return () => {
			if (searchTimeoutId) {
				clearTimeout(searchTimeoutId);
			}
		};
	}, [searchTimeoutId]); // Dependency on searchTimeoutId

	return (
		<div className={` ${activeMenu ? '' : ''}`}>
			<div
				className={` h-fit m-2 mt-16 md:m-10 p-6 md:p-10  rounded-3xl bg-white max-w-screen md:max-w-full`}
			>
				{/* <Header category={"Filter Data"} /> */}
				<p className=" text-base font-semibold mb-7 ">Filter Data</p>

				<div className=" md:flex gap-5 mb-5">
					{/* start date input */}
					<div className=" mb-5 md:mb-0 w-full">
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								className=" w-full"
								label="Start Date"
								value={startDate}
								onChange={(newValue) => {
									setStartDate(newValue);
									setEndDate(newValue);
								}}
								inputFormat="DD-MM-YYYY"
								maxDate={dateTo}
								renderInput={(params) => <TextField {...params} />}
							/>
						</LocalizationProvider>
					</div>
					{/* end date input */}
					<div className=" w-full">
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								className=" w-full"
								label="Until Date"
								value={endDate}
								onChange={(newValue) => {
									setEndDate(newValue);
								}}
								inputFormat="DD-MM-YYYY"
								minDate={startDate}
								maxDate={dateToMax}
								renderInput={(params) => <TextField {...params} />}
							/>
						</LocalizationProvider>
					</div>
				</div>

				{/* select Transaction Status dan Payment Category */}
				<div className=" flex gap-3 mb-3">
					<div className=" w-full">
						<Autocomplete
							required
							fullWidth={true}
							style={{
								width: 'auto',
							}}
							disablePortal
							id="jenis-user"
							options={dataCategory}
							defaultValue={dataCategory[0]}
							onChange={onChangePembayaran}
							renderInput={(params) => (
								<TextField
									required
									{...params}
									label="Payment Category"
								/>
							)}
						/>
					</div>

					{/* corporate insert */}
					<div className="w-full -mr-0 md:-mr-0  ">
						<Autocomplete
							// key={currentIndex}
							required
							fullWidth={true}
							style={{
								width: 'auto',
							}}
							disablePortal
							id="combo-box-demo"
							options={corpData}
							// defaultValue={corpData[0]}
							value={value}
							onChange={onChangeCorp}
							inputValue={dropdownSearch}
							onInputChange={(event, newInputValue) => {
								setCorporateLoading(true);
								setDropdownSearch(newInputValue);
								// Clear any existing timeout before starting a new one
								if (searchTimeoutId) {
									clearTimeout(searchTimeoutId);
								}

								setSearchTimeoutId(
									setTimeout(() => {
										dropdownSearch && getCorporate();
									}, 2000)
								);
							}}
							disableClearable
							renderInput={(params) => (
								<TextField
									required
									{...params}
									label="Corporate"
									placeholder="- All Corporate -"
									// InputLabelProps={{ shrink: true }}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{corporateLoading ? (
													<CircularProgress
														color="inherit"
														size={20}
													/>
												) : (
													value !== null &&
													dropdownSearch !== '' && (
														<InputAdornment position="end">
															<IconButton
																aria-label="clear"
																onClick={() => {
																	setDropdownSearch('');
																	setValue(null);
																	setCorporate('');
																	// setDataCorporate([]);
																}}
																edge="end"
															>
																<ClearIcon />
															</IconButton>
														</InputAdornment>
													)
												)}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					</div>
				</div>

				<div className=" mb-7 w-full">
					<Autocomplete
						multiple
						required
						fullWidth={true}
						style={{
							width: 'auto',
						}}
						disablePortal
						id="combo-box-demo"
						options={dataCoreSettle}
						value={selected}
						getOptionLabel={(option) => option.label}
						onChange={handleChange}
						filterSelectedOptions
						renderInput={(params) => (
							<TextField
								required
								{...params}
								label="Choose settlement"
							/>
						)}
					/>
				</div>
				<Button
					customFunc={() => handleClick()}
					bgColor={currentColor}
					color="white"
					text="Filter"
					borderRadius={'10px'}
					icon={<FiRefreshCw />}
				/>
			</div>

			{/* Success Transaction Summary */}
			<div
				className={` h-fit   md:m-10 p-6 md:p-10  rounded-3xl bg-white 
      ${activeMenu === true ? 'max-w-full' : ' max-w-full'}`}
			>
				<div className=" flex justify-between">
					<p> </p>
					{checkIsAvailableModule(
						'REPORT_SETTLE_PDF_SUMMARY',
						privilege
					) && (
						<PDFDownloadLink
							document={dokumen(successData)}
							fileName={`Data Summary (${dateStarted} - ${dateEnded})`}
						>
							<Button
								bgColor={currentColor}
								color="white"
								text="Export to PDF"
								borderRadius={'10px'}
								icon={<TiDocument />}
							/>
						</PDFDownloadLink>
					)}
				</div>

				<div>
					<div className=" flex items-center gap-4 mb-4">
						<p className=" text-2xl">
							<BiDollar />
						</p>
						<p className=" font-semibold">Summary</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute ">
							Periode:
						</p>
						<p className=" text-gray-600  text-sm md:text-base font-normal ml-44 md:ml-72">
							{successData.startDate} - {successData.endDate}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute ">
							Corporate:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-44 md:ml-72">
							{successData.corporate ? successData.corporate : '-'}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute ">
							Pembayaran:
						</p>
						<p className=" text-gray-600  text-sm md:text-base font-normal ml-44 md:ml-72">
							{successData.pembayaran === ''
								? 'All'
								: successData.pembayaran}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Status:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-44 md:ml-72">
							{successData.status ? successData.status : '-'}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Settlement Destination:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-44 md:ml-72">
							{successData.settlementDestination
								? successData.settlementDestination
								: '-'}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Amount of transaction:
						</p>
						<p className=" text-gray-600  text-sm md:text-base font-normal ml-44 md:ml-72">
							{successData.jumlahTrx.format()} Transaksi
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Tunai:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalTunai.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Lainnya:
						</p>
						<p className=" text-gray-600  text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalLain.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total CC:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalCC.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Debit:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalDebit.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total QRIS:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalQRIS.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Brizzi:
						</p>
						<p className=" text-gray-600  text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalBrizzi.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total E-Money:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalEMoney.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Tapcash:
						</p>
						<p className=" text-gray-600  text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalTapCash.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Flazz:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalFlazz.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total JakCard:
						</p>
						<p className=" text-gray-600  text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalJakcard.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total VA:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalVA.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Bruto:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalBruto.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total MDR:
						</p>
						<p className=" text-gray-600  text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalMDR.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600  text-sm md:text-base font-normal absolute">
							Total Potongan:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalPotongan.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Service Fee:
						</p>
						<p className=" text-gray-600  text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalServiceFee.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Payment Fee:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalPaymentFee.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Vendor Fee:
						</p>
						<p className=" text-gray-600  text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalVendorFee.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Nett:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-44 md:ml-72">
							Rp. {successData.totalNett.format()}
						</p>
					</div>
				</div>
			</div>
			{/* bottom container */}
			<div
				className={` h-fit   md:m-10 p-6 md:p-10  rounded-3xl bg-white 
      ${activeMenu === true ? 'max-w-full' : ' max-w-full'}`}
			>
				<div className=" flex justify-between mb-5">
					<div className=" flex items-center gap-4 mt-5 mb-4">
						<p className=" text-2xl">
							<BiSpreadsheet />
						</p>
						<p className=" font-semibold">Transaction Data</p>
					</div>
					{checkIsAvailableModule(
						'REPORT_SETTLE_EXCEL_LIST',
						privilege
					) && (
						<Button
							customFunc={() => getDataExcel()}
							// customFunc={() => alert(currentAuth)}
							text="Excel Export"
							bgColor={currentColor}
							color="white"
							height="fit"
							borderRadius="10px"
							icon={<BsFileEarmarkExcelFill />}
						/>
					)}
				</div>

				<div className=" mt-5 flex justify-end">
					<div className=" w-1/2 mb-2 flex end">
						<div className=" w-full mr-2">
							<Autocomplete
								required
								size="small"
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								disableClearable
								id="status-trx"
								options={dataFilter}
								defaultValue={dataFilter[0]}
								onChange={onChangeSelectedFilter}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Filter Field"
									/>
								)}
							/>
						</div>
						<FormControl className=" w-full" variant="outlined">
							<OutlinedInput
								size="small"
								notched
								id="outlined-adornment-password"
								type={'text'}
								placeholder="Search"
								onChange={(text) => setKeyword(text.target.value)}
								value={keyword}
								defaultValue={keyword}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										searchData();
									}
								}}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											onClick={() => searchData()}
											edge="end"
										>
											<MdSearch />
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
					</div>
				</div>
				<DataTable
					columns={listTransactionColumnSettle(
						dataStatusCode,
						(id, isVoid = false) => {
							if (!isVoid) setSelectedDetail(id);
							else setSelectedVoid(id);
						},
						privilege
					)}
					noDataComponent={
						<div className=" justify-center">
							<img
								src={notfound}
								style={{
									width: 'auto',
									height: screenWidth >= 500 ? 400 : 200,
								}}
							/>
						</div>
					}
					data={listTrx}
					pagination
					paginationServer
					paginationTotalRows={count}
					paginationDefaultPage={currentPage}
					onChangeRowsPerPage={handlePerRowsChange}
					onChangePage={handlePageChange}
					sortServer
					onSort={customSort}
					theme="solarized"
					customStyles={customStyles}
				/>
			</div>

			<TransactionDetail
				open={selectedDetail}
				onClose={() => setSelectedDetail(null)}
				width={MatrixUtils.isMobile() ? MatrixUtils.width() : 400}
				data={getDetailData(selectedDetail)}
				onSuccess={() => {
					setSelectedDetail(null);
					getSuccessSummary();
					getSuccessTransaction();
				}}
			/>

			<VoidTransaction
				data={getDetailData(selectedVoid)}
				onSuccess={() => {
					setSelectedVoid(null);
					getSuccessSummary();
					getSuccessTransaction();
				}}
				onCancel={() => {
					setSelectedVoid(null);
				}}
			/>
		</div>
	);
};

const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: 35,
	},
	title: {
		fontSize: 24,
		textAlign: 'center',
	},
	textHeader: {
		fontSize: 11,
		textAlign: 'justify',
		color: '#3B3B3B',
		marginBottom: 5,
	},
	textHeader2: {
		fontSize: 11,
		textAlign: 'justify',
		marginBottom: 5,
	},
	text1: {
		margin: 12,
		fontSize: 11,
		textAlign: 'justify',
	},
	text2: {
		top: 5,
		margin: 12,
		fontSize: 11,
		textAlign: 'justify',
		left: 96,
		marginLeft: 96,
		position: 'absolute',
		width: 400,
	},
	image: {
		marginVertical: 15,
		marginHorizontal: 100,
	},
	header: {
		fontSize: 12,
		marginBottom: 20,
		textAlign: 'center',
		color: 'grey',
	},
	pageNumber: {
		position: 'absolute',
		fontSize: 12,
		bottom: 30,
		left: 0,
		right: 0,
		textAlign: 'center',
		color: 'grey',
	},
	section: {
		backgroundColor: '#F8F8F8',
		width: '100%',
		padding: 10,
		paddingTop: 5,
		paddingBottom: 5,
		flexDirection: 'row',
	},
	section2: {
		backgroundColor: '#ffffff',
		width: '100%',
		padding: 10,
		paddingTop: 5,
		paddingBottom: 5,
		flexDirection: 'row',
	},
});

const dokumen = (successData) => {
	return (
		<Document>
			<Page style={styles.body}>
				<Image
					src={LogoMKP}
					style={{ width: 100, height: 100, alignSelf: 'center' }}
				/>
				<Image
					src={HeaderPDF}
					style={{
						height: 30,
						width: '100%',
						alignSelf: 'center',
						marginBottom: 10,
					}}
				/>
				<View style={{ flexDirection: 'row', marginBottom: 5 }}>
					<View style={{ alignItems: 'flex-end' }}>
						<Text style={styles.textHeader}>Periode:</Text>
						<Text style={styles.textHeader}>Transaction Status:</Text>
						<Text style={styles.textHeader}>Corporate:</Text>
						<Text style={styles.textHeader}>Payment Category:</Text>
						<Text style={styles.textHeader}>
							Settlement Destination:
						</Text>
					</View>
					<View style={{ marginLeft: 10 }}>
						<Text style={styles.textHeader2}>
							{successData.startDate} - {successData.endDate}
						</Text>
						<Text style={styles.textHeader2}>
							{successData.status}
						</Text>
						<Text style={styles.textHeader2}>
							{successData.corporate}
						</Text>
						<Text style={styles.textHeader2}>
							{successData.pembayaran}
						</Text>
						<Text style={styles.textHeader2}>
							{successData.settlementDestination}
						</Text>
					</View>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Amount of transaction:</Text>
					<Text style={styles.text2}>
						{successData.jumlahTrx.format02()} Transaksi
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total Tunai:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalTunai.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total Lainnya:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalLain.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total CC:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalCC.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total Debit:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalDebit.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total QRIS:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalQRIS.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total Brizzi:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalBrizzi.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total E-Money:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalEMoney.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total Tapcash:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalTapCash.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total Flazz:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalFlazz.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total JakCard:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalJakcard.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total Bruto:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalBruto.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total VA:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalVA.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total MDR:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalMDR.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total Potongan:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalPotongan.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total Service Fee:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalServiceFee.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total Payment Fee:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalPaymentFee.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total Vendor Fee:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalVendorFee.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total Nett:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalNett.format()}
					</Text>
				</View>
				<Text
					style={styles.pageNumber}
					render={({ pageNumber, totalPages }) =>
						'© 2020 MKP Mobile All rights reserved.'
					}
				/>
			</Page>
		</Document>
	);
};

export default SettledTransaction;
